<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.lend_key") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="card grid p-4">
      <custom-form
        :formElements="formElements"
        formStyle="inline"
        @submitForm="lendKey"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import CustomForm from "../components/form/CustomForm.vue";
import { getISODate } from "../utils/dateUtils";
export default {
  components: { Breadcrumb, CustomForm },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    const company = computed(() => store.state.company.company);
    const key = computed(() => store.state.key.key);
    const user = computed(() => store.state.authentication.user);

    const waiting = computed(() => {
      if (store.state.key.waiting) return true;
      return false;
    });

    const formElements = ref([
      {
        class: "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
        element: "input",
        id: "borrower",
        label: "borrower_name",
        rules: ["isRequired"],
        type: "text",
        value: "",
        variable: "borrower",
      },
      {
        class: "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
        element: "input",
        id: "lender",
        label: "lender_name",
        rules: ["isRequired"],
        type: "text",
        value: "",
        variable: "lender",
      },
      {
        class: "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
        element: "datepicker",
        id: "lending-date",
        label: "lending_date",
        rules: ["isRequired"],
        textVariant: "white",
        value: getISODate(new Date()),
        variable: "dateBorrowed",
        variant: "indigo",
      },
      {
        class: "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
        element: "textarea",
        id: "additional-info",
        label: "additional_info",
        rows: 5,
        rules: [],
        value: "",
        variable: "additionalInfo",
      },
      {
        class: "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
        element: "signature",
        id: "signature",
        label: "signature",
        rules: ["isRequired"],
        textVariant: company.value.primaryText,
        value: null,
        variable: "signature",
        variant: company.value.primary,
      },
      {
        class: "col-span-12 text-right",
        disabled: waiting.value,
        element: "button",
        id: "submit-button",
        label: "key_lend",
        textVariant: company.value.primaryText,
        type: "submit",
        variant: company.value.primary,
      },
    ]);

    const lendKey = (formData) => {
      const data = {
        borrower: formData.borrower,
        companyId: company.value.id,
        dateBorrowed: formData.dateBorrowed,
        keyId: key.value.id,
        lender: formData.lender,
        lenderId: user.value.id,
        file: {
          name: "signature.png",
          file: formData.signature,
        },
      };
      store.dispatch("key/lendKey", data);
    };

    onMounted(() => {
      if (!key.value.id || key.value.id != route.params.keyId) {
        store.dispatch("key/getKey", route.params.keyId);
      }
    });

    return {
      formElements,
      key,
      lendKey,
      route,
      t,
      waiting,
    };
  },
};
</script>
